import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"
import { project } from "./project/project"

function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function ToolTip() {
  const { totalWidth, width, height, mainColor, isTouch, stroke } = useState(hive.state)
  const { poi, lot } = useState(hive.overState)

  const a_width = height.get() * 0.20
  const a_height = height.get() * 0.19
  // const color = lot.get() ? typeColors.get()[lot.get().type - 1] : [255, 255, 255]

  useEffect(() => {
    const el = document.querySelector("#tooltip") as HTMLElement
    hive.poiOverevent.subscribe(e => {
      stroke.set(false)
      if (lot.get() && lot.get().custom_tooltip) {
        const a_height = height.get() * 0.19
        const a_width = height.get() * 0.45
        const isOnBottom = e.clientY > height.get() * 0.3
        // el.style.top = `${e.clientY - (isOnBottom ? a_height + a_height * 0.05 : a_height * -0.10)}px`
        // el.style.left = `${e.clientX - a_width / 2}px`
        if (lot.get().custom_tooltip.img === "/tip/cellule.png") {
          el.style.top = `${e.clientY - (height.get() * 0.05)}px`
        } else {
          el.style.top = `${e.clientY + 40}px`
        }

        if (lot.get().custom_tooltip.img === "/tip/traverse.png") {
          stroke.set(true)
        } 
        el.style.left = `${e.clientX - height.get() * (lot.get().custom_tooltip.img2 ? 0.2 : 0.1)}px`
      }
      // el.style.backgroundImage = `url(/gui/tooltip_${isOnBottom ? 'down' : "up"}.png)`
      // if (lot.get() && lot.get().custom_tooltip) {
      //   el.style.backgroundImage = `url(${lot.get().custom_tooltip.img})`
      // }
    })
  }, [])

  const iconSize = {
    width: a_height * 0.7 * 0.25 * 0.7,
    height: a_height * 0.7 * 0.25 * 0.7
  }

  return <>
    <div id="tooltip" style={{
      position: "absolute",
      // background: "red",
      pointerEvents: "none",
    }}>
      {lot.get() && <>
        <div style={{
          marginLeft: lot.get().custom_tooltip.img2 ? `${height.get() * 0.1}px` : "initial",
        }}>
          <img src={lot.get().custom_tooltip.img} width={height.get() * 0.2}></img>
        </div>
        {lot.get().custom_tooltip.img2 && <div style={{
          position: "absolute",
          marginTop: lot.get().custom_tooltip.img2 === "/tip/aire_jeux_2.jpg" ? `${-height.get() * 0.54}px` : "initial",
        }}>
          <img src={lot.get().custom_tooltip.img2} width={height.get() * 0.4}></img>
        </div>}
      </>}
    </div>
  </>
}

export default ToolTip